import { Box, Grid, Link, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Navbar } from '../components/Navbar'
import useCache from '../hook/useCache'
import axios from 'axios'

const Projects = () => {

    const { getCache, setCacheData } = useCache();
    const projects = getCache("allProjects");



    useEffect(() => {
        const fetchProjects = async () => {
            const res = await axios.get(
                "https://lyss.pythonanywhere.com/api/projects"
            );
            if (res.status === 200) setCacheData("allProjects", res.data);
        };
        fetchProjects();
    }, []);
    return (
        <Box
            sx={{
                overflowX: "hidden",
                backgroundColor: "whitesmoke",
                color: "#113",
                fontFamily: "math",
                minHeight: "100vh",
                height: "auto",
                textAlign: "center",
            }}
        >
            <Helmet>
                <title>Lyss Technology Pvt. Ltd. | About</title>
            </Helmet>
            <Navbar />

            <Box
                sx={{
                    marginTop: "70px",
                    width: "100vw",
                    textAlign: "center",
                    backgroundImage: `url(../images/about.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    paddingTop: { xs: "8vw", lg: "2vw", md: "2vw" },
                    paddingBottom: "15vw",
                    position: "relative",
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.6)", // Overlay with opacity
                        zIndex: 1,
                    },
                }}
            />

            <p style={{ fontSize: "2.5rem", fontWeight: 600, marginTop: "50px", marginBottom: "30px" }} className="header">
                Our Projects
            </p>

            <Grid container spacing={2} justifyContent="left" gap={2} padding={'30px'}>
                {projects.slice(0, 5).map((project, index) => (
                    <Link
                        key={index}
                        href={project.Project_Link}
                        target="_blank"
                        rel="noreferrer"
                        underline="none"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 20px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s, box-shadow 0.3s",
                            "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                            },
                            borderRadius: "8px",
                        }}
                    >
                        {/* <Grid item sm={6} xs={6} md={3} lg={2} flex={1}> */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "10px",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <Box className="wrap">
                                <iframe className="frame" src={project.Project_Link} title={project.Project_Name} />
                            </Box>
                            <Box
                                sx={{
                                    justifySelf: "end",
                                    marginTop: "5px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    gutterBottom
                                    variant="h4"
                                    color="text.primary"
                                    component="div"
                                    sx={{ fontSize: "1rem", fontWeight: "bold" }}
                                >
                                    {project.Project_Name}
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    color="text.primary"
                                    sx={{
                                        fontSize: "0.7rem",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {project.Project_Type}
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="body2"
                                    color="text.secondary"
                                    title={project.Project_Description}
                                    sx={{
                                        fontSize: "0.7rem",
                                        maxWidth: "300px",
                                        marginTop: "auto",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        WebkitLineClamp: 2,
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {project.Project_Description}
                                </Typography>
                            </Box>
                        </Box>
                        {/* </Grid> */}
                    </Link>
                ))}
            </Grid>
        </Box>
    )
}

export default Projects