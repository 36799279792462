import { useState, useCallback } from "react";

const useCache = () => {
  const [cache, setCache] = useState(() => {
    const storedCache = localStorage.getItem("cache");
    return storedCache ? JSON.parse(storedCache) : {};
  });

  // Function to retrieve data by key
  const getCache = useCallback(
    (key) => {
      return cache[key] || [];
    },
    [cache]
  );

  // Function to store or update array data for a given key
  const setCacheData = useCallback((key, data) => {
    setCache((prevCache) => {
      const newCache = { ...prevCache };

      if (newCache[key]) {
        // Create a map of existing objects by their IDs for quick lookup
        const existingDataMap = new Map(
          newCache[key].map((item) => [item.id, item])
        );

        // Merge or add new data based on the ID
        data.forEach((newItem) => {
          existingDataMap.set(newItem.id, {
            ...existingDataMap.get(newItem.id),
            ...newItem,
          });
        });

        // Update the cache with the merged data
        newCache[key] = Array.from(existingDataMap.values());
      } else {
        // Set new data for the key
        newCache[key] = data;
      }

      // Save updated cache to localStorage
      localStorage.setItem("cache", JSON.stringify(newCache));

      return newCache;
    });
  }, []);

  // Function to clear cache for a specific key
  const clearCache = useCallback((key) => {
    setCache((prevCache) => {
      const newCache = { ...prevCache };
      delete newCache[key];
      localStorage.setItem("cache", JSON.stringify(newCache));
      return newCache;
    });
  }, []);

  return { getCache, setCacheData, clearCache };
};

export default useCache;
