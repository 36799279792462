import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Error } from './pages/404';
import { Contact } from './pages/Contact';
import { Gallary } from './pages/Gallary';
import { Login } from './pages/Login';
import { Product } from './pages/Product';
import { SmartOne } from './components/SmartOne';
import { Atplc } from './pages/Atplc';
import { Crcs } from './pages/Crcs';
import { Samp } from './pages/Samp';
import { UnderDevelopment } from './pages/Underdevelopment';
import { Technologies } from './pages/Technologies';
import { RefundPolicy } from './pages/RefundPolicy';
import { TermsConditions } from './pages/TermsCondition';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { PaymentPage } from './pages/PaymentPage';
import { Careers } from './pages/Careers';
import Perks from './pages/Perks';
import ExamLinks from './pages/ExamLinks';
import { OurPartners } from './pages/OurPartners';
import { OurInsights } from './pages/OurInsights';
import Projects from './pages/Projects';
function App() {
  return (

    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/atplc' element={<Atplc />} />
        <Route path='/Careers' element={<Careers />} />
        <Route path='/CRCS' element={<Crcs />} />
        <Route path='/examlinks' element={<ExamLinks />} />
        <Route path='/gallary' element={<Gallary />} />
        <Route path='/insights' element={<OurInsights />} />
        <Route path='/login' element={<Login />} />
        <Route path='/partners/:section' element={<OurPartners />} />
        <Route path='/payment' element={<PaymentPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/products' element={<Product />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/smartOne' element={<SmartOne />} />
        <Route path='/Samp' element={<Samp />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
        <Route path='/technologies' element={<Technologies />} />
        <Route path='/underdevelopment' element={<UnderDevelopment />} />
        <Route path='/community' element={<Perks />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </Router>

  );
}

export default App;
