import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardActions, CardContent, CardMedia, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { styled } from '@mui/system';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RiArrowLeftSLine } from "react-icons/ri";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import '../App.css';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Autoplay } from 'swiper/modules';
import axios from 'axios';

gsap.registerPlugin(ScrollTrigger);

const SliderContainer = styled('div')({
  width: '100%',
});



const OurTeam = () => {
  const swiperRef = useRef(null);

  const [developers, setDevelopers] = useState([]);

  const getDeveloperTeam = async () => {
    try {
      const res = await axios.get('https://lyss.pythonanywhere.com/api/developers/');
      if (res.status === 200)
        setDevelopers(res.data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDeveloperTeam();
  }, [])


  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // const images = [
  //   '../images/amarnath.jpg',
  //   '../images/someswar.jpg',
  //   '../images/siddharth.jpg',
  //   '../images/md.jpg'
  // ];

  // const Cards = [
  //   'Amarnath Apuri',
  //   'Someswar Gorai',
  //   'Siddharth Maddheshiya',
  //   'Md Mushfikur Talukdar'
  // ];

  // const Address = [
  //   'Hyderabad, India',
  //   'West Bengal, India',
  //   'Uttarpradesh, India',
  //   'Dhaka, Bangladesh'
  // ];

  // const Tagline = [
  //   'Backend Developer',
  //   "Frontend Developer",
  //   "Frontend Developer",
  //   "Frontend Developer"

  // ]

  return (
    <Box>
      <SliderContainer>

        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          autoplay={{
            delay: 3000, // 3 seconds delay between slides
            disableOnInteraction: false, // Autoplay won't be disabled after manual interaction
          }}
          breakpoints={{
            640: { slidesPerView: 1 },  // Extra-small screens (xs)
            768: { slidesPerView: 1 },  // Small screens (sm)
            1024: { slidesPerView: 1 }, // Medium screens (md)
            1280: { slidesPerView: 1 }, // Large screens (lg)
          }}
          modules={[Autoplay]}
        >

          {developers.map((developer, index) => (
            <SwiperSlide key={index} >
              <Grid container justifyContent="center">
                <Grid item xs={11} sm={11} md={11} lg={8} >
                  <center>
                    <Card className='hoverCard' sx={{ width: { lg: 400, xs: 300, md: 400, sm: 350 } }}>
                      <center>
                        <CardMedia
                          component="img"
                          alt={developer.name}

                          image={developer.image_url}
                          sx={{
                            objectFit: "contain",
                            height: {
                              xs: 230,
                              lg: 290,
                              md: 400,
                              sm: 300
                            },
                            width: {
                              xs: 300,
                              lg: 427,
                              md: 400,
                              sm: 350
                            }
                          }}
                          onError={() => console.error(`Failed to load image: ${developer.image_url}`)}
                        /></center>
                      <CardContent>
                        <Typography gutterBottom variant="p" color="text.secondary" component="div" sx={{ fontSize: '1.4rem' }}>
                          {developer.name}
                        </Typography>
                        <Divider style={{ padding: "1px", backgroundColor: "rgb(79 79 138)", width: "80px", marginBottom: "10px" }} />
                        <Typography gutterBottom variant="p" color="text.secondary" sx={{ fontSize: '1.0rem' }}>
                          {developer.position}
                        </Typography>
                        {/* <br />
                        <Typography gutterBottom variant="p" color="text.secondary" sx={{ fontSize: '1.0rem' }}>
                          {Address[index]}
                        </Typography> */}

                      </CardContent>
                      <CardActions sx={{ justifyContent: "center" }}>
                        <IconButton
                          href={developer.linkedIn_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ color: "#0072b1" }}
                        >
                          <LinkedInIcon fontSize="large" />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </center>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </SliderContainer>
      <Box display="flex" justifyContent="center" mt={4} gap={5} >

        <RiArrowLeftSLine onClick={handlePrev} style={{ fontSize: "1.2rem" }} />


        <MdOutlineKeyboardArrowRight onClick={handleNext} style={{ fontSize: "1.2rem" }} />

      </Box>
    </Box>
  );
};

export default OurTeam;
