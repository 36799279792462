import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Navbar } from "../components/Navbar";
import Footer from "../components/Footer";
import { IoMdArrowRoundUp } from "react-icons/io";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

export const OurPartners = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [institutePartners, setInstitutePartners] = useState([]);
  const { section } = useParams();

  const fetchPartners = async () => {
    const res = await axios.get(
      "https://lyss.pythonanywhere.com/api/partners"
    );
    if (res.status === 200) setInstitutePartners(res.data);
  };

  useEffect(() => {

    fetchPartners();
  }, []);

  const industryPartners = [
    {
      name: "Startup Club India",
      description:
        "A leader in innovative tech solutions, collaborating on projects that drive progress.",
      email: "info@startupclub.in",
      website: "https://startupclub.in",
      avatar: "../images/startupclub.png",
    },
    {
      name: "Tech Innovators Inc.",
      description:
        "A leader in innovative tech solutions, collaborating on projects that drive progress.",
      email: "contact@techinnovators.com",
      website: "https://techinnovators.com",
      avatar: "../images/techinnovators.png",
    },
    {
      name: "Green Tech Alliance",
      description:
        "A consortium focused on sustainable technology and green energy initiatives.",
      email: "support@greentechalliance.org",
      website: "https://greentechalliance.org",
      avatar: "../images/greentech.png",
    },
  ];

  // const institutePartners = [
  //   {
  //     name: "Sandeep University",
  //     description:
  //       "A renowned private university offering multidisciplinary education.",
  //     email: "info@sandeepuniversity.edu",
  //     website: "https://sandeepuniversity.edu",
  //     avatar: "../images/sandeepuniversity.png",
  //   },
  //   {
  //     name: "GEC Aurangabad",
  //     description:
  //       "Government Engineering College, Aurangabad, a prestigious institute.",
  //     email: "contact@geca.ac.in",
  //     website: "https://geca.ac.in",
  //     avatar: "../images/geca.png",
  //   },
  //   {
  //     name: "Tech Academy",
  //     description:
  //       "An esteemed institute for advanced studies in technology and innovation.",
  //     email: "hello@techacademy.org",
  //     website: "https://techacademy.org",
  //     avatar: "../images/techacademy.png",
  //   },
  // ];

  useEffect(() => {
    const decodedSection = decodeURIComponent(section); // Decode the section from the URL

    // Select the appropriate element by ID
    const targetElement = document.getElementById(
      decodedSection === "Our Institute Partners"
        ? "Our-Institute-Partners"
        : "Our-Industry-Partners"
    );

    // Scroll to the element smoothly if it exists
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [section]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        overflowX: "hidden",
        backgroundColor: "whitesmoke",
        color: "#113",
        fontFamily: "math",
      }}
    >
      <Helmet>
        <title>Lyss Technology Pvt. Ltd. | Partners</title>
      </Helmet>
      <Navbar />

      <Box
        sx={{
          marginTop: "70px",
          textAlign: "center",
          backgroundImage: `url(../images/about.png)`,
          backgroundSize: "cover",
          paddingTop: { xs: "8vw", lg: "4vw" },
          paddingBottom: "10vw",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 1,
          },
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
            zIndex: 2,
            color: "white",
            padding: { xs: "20px", sm: "30px", md: "50px" },
          }}
        >
          <Grid item xs={12} lg={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                fontWeight: "bold",
                marginTop: { xs: "20px", md: "80px" },
              }}
            >
              Our Partners
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.2rem" },
                marginTop: "10px",
                fontWeight: "500",
                padding: { xs: "10px", md: "0px" },
              }}
            >
              We are a team of passionate individuals dedicated to fostering
              long-lasting partnerships that drive mutual growth and success.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box>
        {scrollPosition > 150 && (
          <Button
            sx={{
              position: "fixed",
              bottom: "20px",
              right: "10px",
              zIndex: "10",
              fontSize: "1.5rem",
              backgroundColor: "#7a7ad4",
              borderRadius: "40%",
            }}
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <IoMdArrowRoundUp style={{ color: "white" }} />
          </Button>
        )}
      </Box>

      <Container sx={{ mt: 5 }}>
        <Box textAlign="center" mb={5}>
          <Typography variant="h4" fontWeight="bold" className="header">
            Our Partners
          </Typography>
          <Divider
            sx={{
              backgroundColor: "blue",
              width: { xs: "50%", lg: "10%" },
              margin: "auto",
              mb: 2,
            }}
          />
          <Typography
            variant="body1"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            Strong partnerships are the foundation of success, built on trust
            and shared goals.
          </Typography>
        </Box>

        {/* Industry Partners */}
        <Typography
          variant="h5"
          fontWeight="bold"
          mb={3}
          className="header"
          id="Our-Industry-Partners"
        >
          Our Industry Partners
        </Typography>
        <Grid container spacing={4} mb={5}>
          {industryPartners.map((partner, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  borderRadius: 2,
                  boxShadow: 3,
                  height: "260px",
                  padding: 2,
                }}
              >
                <Box alignItems="center" gap={2}>
                  <Typography variant="h6" fontWeight="bold">
                    {partner.name}
                  </Typography>
                </Box>

                <center>
                  <Avatar
                    src={partner.avatar}
                    alt={partner.name}
                    sx={{ marginTop: "20px", width: "80px", height: "80px" }}
                  />
                </center>
                <CardContent>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    {partner.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    Email: {partner.email}
                  </Typography>
                  <Typography variant="body2" color="primary" mt={1}>
                    Website:{" "}
                    <a
                      href={partner.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {partner.website}
                    </a>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Institute Partners */}
        <Typography
          variant="h5"
          fontWeight="bold"
          mb={3}
          className="header"
          id="Our-Institute-Partners"
        >
          Our Institute Partners
        </Typography>
        <Grid container spacing={4}>
          {institutePartners.map((partner, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  borderRadius: 2,
                  boxShadow: 3,
                  height: "260px",
                  padding: 2,
                }}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography variant="h6" fontWeight="bold">
                    {partner.name}
                  </Typography>
                </Box>

                <center>
                  <Avatar
                    src={partner.avatar}
                    alt={partner.name}
                    sx={{ marginTop: "20px", width: "80px", height: "80px" }}
                  />
                </center>
                <CardContent>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    {partner.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    Email: {partner.email}
                  </Typography>
                  <Typography variant="body2" color="primary" mt={1}>
                    Website:{" "}
                    <a
                      href={partner.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {partner.website}
                    </a>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};
