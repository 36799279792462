import React, { useEffect } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import axios from "axios";
import useCache from "../hook/useCache";

export const CustomerSlider = () => {
  const { getCache, setCacheData } = useCache();

  const clients = getCache("clients");


  useEffect(() => {
    const fetchClients = async () => {
      const res = await axios.get("https://lyss.pythonanywhere.com/api/clients");
      if (res.status === 200) setCacheData("clients", res.data);
    };
    fetchClients();
  }, []);

  // const images = [
  //   'https://i.pinimg.com/736x/da/c7/58/dac758d7606690d057a9cbba5f259154.jpg',
  //   'https://i.pinimg.com/736x/79/1f/84/791f846ada8f10df721c42328e78e970.jpg',
  //   'https://e7.pngegg.com/pngimages/923/238/png-clipart-white-and-multicolored-logo-creative-color-logo-design-text-logo.png',
  //   'https://www.clipartmax.com/png/middle/217-2178587_cornerstone-random-business-logos.png',
  //   'https://image.similarpng.com/very-thumbnail/2021/05/Logo-design-illustration-on-transparent-background-PNG.png'
  // ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Grid container spacing={2} justifyContent="center" gap={2}>
        {clients.slice(0, 5).map((client, index) => (
          <Link
            key={index}
            href={client.website_link}
            target="_blank"
            rel="noreferrer"
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "stretch",
              padding: "10px 20px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
              },
              borderRadius: "8px",
            }}
          >
            {/* <Grid item sm={6} xs={6} md={3} lg={2} flex={1}> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <img
                src={client.image_url}
                alt={client.website_link || "Client"}
                style={{
                  width: "100%",
                  minHeight: "100px",
                  maxWidth: "100px",
                  objectFit: "contain",
                }}
              />
              <Box
                sx={{
                  justifySelf: "end",
                  marginTop: "5px",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  color="text.secondary"
                  component="div"
                  sx={{ fontSize: "1rem" }}
                >
                  {client.Name}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: "0.7rem",
                  }}
                >
                  {client.Location}
                </Typography>
              </Box>
            </Box>
            {/* </Grid> */}
          </Link>
        ))}
      </Grid>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <Button>View More...</Button>
      </Box>
    </Box>
  );
};
