import React, { useEffect } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import axios from "axios";
import useCache from "../hook/useCache";
import { useNavigate } from "react-router-dom";

const ProjectSlider = () => {
  const { getCache, setCacheData } = useCache();
  const projects = getCache("projects");

  const navigate = useNavigate()


  useEffect(() => {
    const fetchProjects = async () => {
      const res = await axios.get(
        "https://lyss.pythonanywhere.com/api/projects"
      );
      const project = res.data.filter((item) => {
        return item.Project_Place.toLowerCase() === "f";
      });
      if (res.status === 200) setCacheData("projects", project);
    };
    fetchProjects();
  }, []);

  // const images = [
  //   'https://i.pinimg.com/736x/da/c7/58/dac758d7606690d057a9cbba5f259154.jpg',
  //   'https://i.pinimg.com/736x/79/1f/84/791f846ada8f10df721c42328e78e970.jpg',
  //   'https://e7.pngegg.com/pngimages/923/238/png-clipart-white-and-multicolored-logo-creative-color-logo-design-text-logo.png',
  //   'https://www.clipartmax.com/png/middle/217-2178587_cornerstone-random-business-logos.png',
  //   'https://image.similarpng.com/very-thumbnail/2021/05/Logo-design-illustration-on-transparent-background-PNG.png'
  // ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Grid container spacing={2} justifyContent="center" gap={2}>
        {projects.slice(0, 5).map((project, index) => (
          <Link
            key={index}
            href={project.Project_Link}
            target="_blank"
            rel="noreferrer"
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px 20px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
              },
              borderRadius: "8px",
            }}
          >
            {/* <Grid item sm={6} xs={6} md={3} lg={2} flex={1}> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                width: "100%",
                height: "100%",
              }}
            >
              <Box className="wrap">
                <iframe className="frame" src={project.Project_Link} title={project.Project_Name} />
              </Box>
              <Box
                sx={{
                  justifySelf: "end",
                  marginTop: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h4"
                  color="text.primary"
                  component="div"
                  sx={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  {project.Project_Name}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  color="text.primary"
                  sx={{
                    fontSize: "0.7rem",
                    textTransform: "uppercase",
                  }}
                >
                  {project.Project_Type}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="text.secondary"
                  title={project.Project_Description}
                  sx={{
                    fontSize: "0.7rem",
                    maxWidth: "300px",
                    marginTop: "auto",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                    textOverflow: "ellipsis",
                  }}
                >
                  {project.Project_Description}
                </Typography>
              </Box>
            </Box>
            {/* </Grid> */}
          </Link>
        ))}
      </Grid>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <Button onClick={() => navigate('/projects')}>View More...</Button>
      </Box>
    </Box>
  );
};

export default ProjectSlider;
