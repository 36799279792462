import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdEmail, MdPhone, MdPerson } from "react-icons/md";
import { Navbar } from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

// Yup validation schema
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  contact: yup
    .string()
    .matches(/^[0-9]{10}$/, "Contact must be a 10-digit number")
    .required("Contact number is required"),
  resume: yup
    .mixed()
    .required("PDF file is required")
    .test("fileType", "File should be a PDF", (value) => {
      if (!value || value.length === 0) return false; // If no file is uploaded
      return value[0]?.type === "application/pdf"; // Ensure it's a PDF
    })
    .test("fileSize", "File size must be less than 5MB", (value) => {
      if (!value || value.length === 0) return false;
      return value[0]?.size <= 5 * 1024 * 1024; // 5MB in bytes
    }),
});

export const Careers = () => {
  const [openDialog, setOpenDialog] = useState(false);

  // React Hook Form with Yup validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    reset(); // Reset the form when dialog closes
  };

  // Submit handler
  const onSubmit = (data) => {
    console.log("Form Submitted Data: ", data);
    handleClose(); // Close dialog after submission
  };

  return (
    <Box
      style={{
        overflowX: "hidden",
        backgroundColor: "whitesmoke",
        color: "#113",
        fontFamily: "math",
      }}
    >
      <Helmet>
        <title>Lyss Technology Pvt. Ltd. | Careers</title>
      </Helmet>
      <Navbar />

      {/* Hero Section */}
      <Box
        sx={{
          marginTop: "70px",
          width: "100vw",
          textAlign: "center",
          backgroundImage: `url(../images/careers.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          paddingTop: { xs: "8vw", lg: "2vw", md: "2vw" },
          paddingBottom: "15vw",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Overlay with opacity
            zIndex: 1,
          },
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
            zIndex: 2,
            color: "white",
            padding: { xs: "20px", sm: "20px", md: "50px" },
          }}
        >
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "2.4rem",
                  md: "2.6rem",
                  lg: "2.6rem",
                },
                marginTop: { xs: "20px", md: "80px" },
                fontWeight: "bold",
              }}
              id="career-hero"
            >
              Careers
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.1rem",
                  md: "1.2rem",
                  lg: "1.2rem",
                },
                marginTop: "10px",
                fontWeight: "500",
                padding: { xs: "10px", sm: "10px", md: "0px" },
              }}
            >
              Join our team and grow your career with us. Explore opportunities
              at Lyss Technology and become part of a leading innovative
              technology company.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Careers Section */}
      <Box sx={{ textAlign: "center", marginTop: "50px" }}>
        <Typography variant="h4" mb={4}>
          Open Positions
        </Typography>
        <center>
          <Divider
            style={{
              padding: "1px",
              backgroundColor: "rgb(79 79 138)",
              width: "180px",
              marginTop: "1px",
              marginBottom: "50px",
            }}
          />
        </center>
        <Grid container spacing={4} justifyContent="center" sx={{padding:"25px"}}>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              sx={{
                padding: "20px",
                border: { xs: "none", sm: "1px solid #ccc" },
                borderRadius: "8px",
                textAlign: "left",
                boxShadow: { xs: "none", sm: "0 4px 8px rgba(0, 0, 0, 0.1)" },
                "&:hover": { boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)" },
              }}
            >
              <Typography variant="h6" mb={2}>
                Software Engineer
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Location: Remote/Bihar, India
              </Typography>
              <Typography variant="body2" mt={2} mb={3}>
                We are looking for a skilled software engineer to join our
                dynamic team. You will work on exciting projects using the
                latest technologies.
              </Typography>
              <Button
                variant="contained"
                onClick={handleClickOpen}
                id="button"
                sx={{
                  width: { lg: "30%", md: "40%", xs: "55%", sm: "40%" },
                  padding: "10px",
                  borderRadius: "25px",
                  marginTop: "10px",
                  backgroundColor: "#5656b9",
                }}
              >
                Apply Now
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              sx={{
                padding: "20px",
                border: { xs: "none", sm: "1px solid #ccc" },
                borderRadius: "8px",
                textAlign: "left",
                boxShadow: { xs: "none", sm: "0 4px 8px rgba(0, 0, 0, 0.1)" },
                "&:hover": { boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)" },
              }}
            >
              <Typography variant="h6" mb={2}>
                Backend Engineer
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Location: Bihar, India
              </Typography>
              <Typography variant="body2" mt={2} mb={3}>
                We are looking for a skilled backend engineer to join our
                dynamic team. You will work on exciting projects using the
                latest technologies.
              </Typography>
              <Button
                variant="contained"
                onClick={handleClickOpen}
                id="button"
                sx={{
                  width: { lg: "30%", md: "40%", xs: "55%", sm: "40%" },
                  padding: "10px",
                  borderRadius: "25px",
                  marginTop: "10px",
                  backgroundColor: "#5656b9",
                }}
              >
                Apply Now
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Dialog for Apply Now */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            backgroundColor: "#f3f4f6", // light background color
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)", // soft shadow
            padding: "5px",
          },
        }}
      >
        <DialogTitle
          textAlign="center"
          sx={{
            fontSize: {xs:"1.2rem",sm:"1.8rem"},
            fontWeight: "bold",
            color: "#1a202c", // darker color for title
            marginBottom: "20px",
          }}
        >
          Apply for the Position
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Name Field */}
            <TextField
              autoFocus
              margin="dense"
              placeholder="John Doe"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdPerson />
                  </InputAdornment>
                ),
              }}
              sx={{
                marginBottom: "15px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
            />

            {/* Email Field */}
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              placeholder="johnDoe@gmail.com"
              fullWidth
              variant="outlined"
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdEmail />
                  </InputAdornment>
                ),
              }}
              sx={{
                marginBottom: "15px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
            />

            {/* Contact Number Field */}
            <TextField
              margin="dense"
              id="contact"
              label="Contact Number"
              type="tel"
              placeholder="1234567890"
              fullWidth
              variant="outlined"
              {...register("contact")}
              error={!!errors.contact}
              helperText={errors.contact?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdPhone />
                  </InputAdornment>
                ),
              }}
              sx={{
                marginBottom: "15px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
            />

            {/* Resume Upload Field */}
            <TextField
              margin="dense"
              id="resume"
              focused
              label="Upload Resume"
              type="file"
              fullWidth
              variant="outlined"
              InputProps={{
                inputProps: { accept: ".pdf, .doc, .docx" },
              }}
              {...register("resume")}
              error={!!errors.resume}
              helperText={errors.resume?.message}
              sx={{
                marginBottom: "15px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
            />

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Footer />
    </Box>
  );
};
