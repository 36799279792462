import React, { useState } from "react";
import {
  Container,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Grid,
} from "@mui/material";
import { Navbar } from "../components/Navbar";
import Footer from "../components/Footer";
import Community from "../components/Community";

const ExamLinks = () => {
  const [open, setOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const links = [
    {
      title: "Problem Solving",
      url:
        "https://www.hackerrank.com/domains/tutorials/10-days-of-problem-solving",
    },
    {
      title: "Algorithms",
      url: "https://www.hackerrank.com/domains/tutorials/10-days-of-algorithms",
    },
    {
      title: "Data Structures",
      url:
        "https://www.hackerrank.com/domains/tutorials/10-days-of-data-structures",
    },
  ];

  const handleClickOpen = (url) => {
    setSelectedLink(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUsername("");
    setEmail("");
  };

  const handleSubmit = () => {
    // You can add any validation or processing here before redirecting
    window.open(selectedLink, "_blank");
    handleClose();
  };

  return (
    <Box sx={{overflowX:"hidden"}}>
      <Navbar />
      <Box
        sx={{
          marginTop: "70px",
          width: "100vw",
          textAlign: "center",
          backgroundImage: `url(../images/perks.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: { xs: "8vw", lg: "2vw", md: "2vw" },
          paddingBottom: "15vw",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Overlay with opacity
            zIndex: 1,
          },
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
            zIndex: 2,
            color: "white",
            padding: { xs: "20px", sm: "20px", md: "50px" },
          }}
        >
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "2.4rem",
                  md: "2.6rem",
                  lg: "2.6rem",
                },
                marginTop: { xs: "20px", md: "80px" },
                fontWeight: "bold",
              }}
              id="hero"
            >
             Exam Links
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.1rem",
                  md: "1.2rem",
                  lg: "1.2rem",
                },
                marginTop: "10px",
                fontWeight: "500",
                padding: { xs: "10px", sm: "10px", md: "0px" },
              }}
              id="hero1"
            >
             In order to strengthen  presence of us among college students, LYSS Technology has brought "LYSS Student Community Lead" program.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}></Grid>
        </Grid>
      </Box>
      <Container >
        <Community />
        <Typography variant="h5" gutterBottom align="center" sx={{ marginTop:"40px" }}>
          HackerRank Links
        </Typography>
        <Grid container spacing={4}>
          {links.map((link, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: "100%",marginTop:"20px" }}>
                <CardContent>
                  <Typography variant="h5" component="div" align="center">
                    {link.title}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    variant="standard"
                    
                    onClick={() => handleClickOpen(link.url)}
                    sx={{ width: "100%",color:"blue" }} // Full width for button
                  >
                    Go to {link.title}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Enter your details</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              variant="standard"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>
      </Container>
      <Footer />
    </Box>
  );
};

export default ExamLinks;
