import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardActions, CardContent, CardMedia, Divider, IconButton, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { styled } from '@mui/system';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RiArrowLeftSLine } from "react-icons/ri";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import '../App.css';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Autoplay } from 'swiper/modules';
import axios from 'axios';

gsap.registerPlugin(ScrollTrigger);

const SliderContainer = styled('div')({
  width: '100%',
});



const KeyPersonal = () => {
  const swiperRef = useRef(null);
  const [leaders, setLeaders] = useState([]);

  const getLeaders = async () => {
    try {
      const res = await axios.get('https://lyss.pythonanywhere.com/api/management/');
      if (res.status === 200)
        setLeaders(res.data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLeaders();
  }, [])


  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // const images = [
  //   '../images/rajiv.png',
  //   '../images/atul.png',
  //   '../images/anjali.png',

  // ];

  // const Cards = [
  //   'Rajiv Ranjan',
  //   'Atul Kumar',
  //   'Anjali Singh',

  // ];

  // const Degree = [
  //   'Mechanical Engineer',
  //   'Software Engineer',
  //   'Electrical Engineer'
  // ]
  // const Tagline = [
  //   'Founder',
  //   'Director',
  //   "Director",

  // ]

  return (
    <Box>
      <SliderContainer>
        <Swiper
          ref={swiperRef}

          autoplay={{
            delay: 5000, // 3 seconds delay between slides
            disableOnInteraction: false, // Autoplay won't be disabled after manual interaction
          }}
          breakpoints={{
            240: { slidesPerView: 1 },  // Extra-small screens (xs)
            768: { slidesPerView: 1 },  // Small screens (sm)
            1024: { slidesPerView: 1 }, // Medium screens (md)
            1280: { slidesPerView: 3 }, // Large screens (lg)
          }}
          modules={[Autoplay]}
        >
          {leaders.map((leader, index) => (
            <SwiperSlide key={index} >
              <center>
                <Card className='hoverCard' sx={{ width: { lg: "400px", xs: "300px", md: "400px", sm: "350px" } }} >
                  <center>

                    <CardMedia
                      component="img"
                      alt={leader.name}

                      image={leader.image_url}
                      sx={{
                        objectFit: "contain", paddingTop: "10px",
                        height: {
                          xs: 300,
                          lg: 400,
                          md: 400,
                          sm: 350
                        },
                        width: {
                          xs: 290,
                          lg: 388,
                          md: 390,
                          sm: 340
                        }
                      }}
                      onError={() => console.error(`Failed to load image: ${leader.image_url}`)}
                    /></center>
                  <CardContent>
                    <Typography gutterBottom variant="p" color="text.secondary" component="div" sx={{ fontSize: '1.4rem' }}>
                      {leader.name}
                    </Typography>
                    <Divider style={{ padding: "1px", backgroundColor: "rgb(79 79 138)", width: "80px", marginBottom: "10px" }} />
                    <Typography gutterBottom variant="p" color="text.secondary" sx={{ fontSize: '1.0rem' }}>
                      {leader.position}
                    </Typography>
                    {/* <br/>
                      <Typography gutterBottom variant="p" color="text.secondary" sx={{fontSize:'1.0rem'}}>
                        {Degree[index]}
                      </Typography> */}

                  </CardContent>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <IconButton
                      href={leader.linkedIn_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: "#0072b1" }}
                    >
                      <LinkedInIcon fontSize="large" />
                    </IconButton>
                  </CardActions>
                </Card>
              </center>
            </SwiperSlide>
          ))}
        </Swiper>
      </SliderContainer>
      <Box display="flex" justifyContent="center" mt={4} gap={5} >

        <RiArrowLeftSLine onClick={handlePrev} style={{ fontSize: "1.2rem" }} />


        <MdOutlineKeyboardArrowRight onClick={handleNext} style={{ fontSize: "1.2rem" }} />

      </Box>
    </Box>
  );
};

export default KeyPersonal;
