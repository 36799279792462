import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { ArrowDropDown, ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const pages = [
  "Home",
  "About",
  "Products",
  "Group Companies",
  "Initiatives",
  "Partners",
  "Gallary",
  "Insights",
  "Careers",
  "Contact Us",
];

export const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [hide, setHide] = React.useState(true);
  const [
    handleDropdownInitiativesClick,
    sethandleDropdownInitiativesClick,
  ] = React.useState(false);
  const [
    handleDropdownProductsClick,
    sethandleDropdownProductsClick,
  ] = React.useState(false);
  const [
    handleDropdownGroupCompanisClick,
    sethandleDropdownGroupCompanisClick,
  ] = React.useState(false);

  const [
    handleDropdownPartnersClick,
    sethandleDropdownPartnersClick,
  ] = React.useState(false);
  const [heading, setHeading] = React.useState("");
  const [content, setContent] = React.useState([]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (page) => {
    if (page === "Contact Us") {
      navigate("/contact");
    } else if (page === "About") {
      navigate("/about");
    } else if (page === "Home") {
      navigate("/");
    } else if (page === "Login") {
      navigate("/login");
    } else if (page === "Gallary") {
      navigate("/gallary");
    } else if (page === "Insights") {
      navigate("/insights");
    } else if (page === "Careers") {
      navigate("/Careers");
    } else {
      if (
        page
          .toString()
          .toLowerCase()
          .startsWith("http")
      ) {
        window.location.href = page;
      } else {
        navigate(`/${page}`);
      }
    }
  };

  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#ffffff",
        position: "fixed",
        top: "0px",
        zIndex: "100",
      }}
    >
      {hide ? (
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* for smaller screen */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "flex", lg: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon style={{ color: "black" }} />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "block", lg: "none" } }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handleNavigate(page)}>
                    {page === "Group Companies" ||
                    page === "Products" ||
                    page === "Initiatives" ||
                    page === "Partners" ? (
                      <Typography textAlign="center">
                        {page}
                        <ArrowRight
                          onClick={(e) => {
                            setHide(false);
                            setHeading(page);
                            if (page === "Group Companies") {
                              setContent([
                                { name: "ATPLC", link: "https://atplc.in" },
                              ]);
                            } else if (page === "Products") {
                              setContent([
                                {
                                  name: "Smartone",
                                  link: "https://smartone.lyss.in",
                                },
                              ]);
                            } else if (page === "Initiatives") {
                              setContent([
                                {
                                  name: "Student Community",
                                  link: "/community",
                                },
                                {
                                  name: "Monthly Coding Challenge",
                                  link: "examlinks",
                                },
                              ]);
                            } else if (page === "Partners") {
                              setContent([
                                {
                                  name: "Our Industry Partners",
                                  link: "partners",
                                },
                                {
                                  name: "Our Institute Partners",
                                  link: "partners",
                                },
                              ]);
                            }
                          }}
                          style={{
                            position: "relative",
                            top: "7px",
                            left: "5px",
                          }}
                        />
                      </Typography>
                    ) : (
                      <Typography textAlign="center">{page}</Typography>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: "block", sm: "block", lg: "none", md: "block" },
              }}
            >
              <Tooltip title="Lyss Technology">
                <Button
                  id="button"
                  sx={{
                    marginRight: "20px",
                    my: 2,
                    backgroundColor: "#5656b9",
                    borderRadius: "27px",
                    width: "83px",
                    color: "white",
                  }}
                  onClick={(e) => handleNavigate("login")}
                >
                  Login
                </Button>
                <IconButton sx={{ p: 0 }} onClick={(e) => handleNavigate("")}>
                  <Avatar
                    alt="Remy Sharp"
                    src="../images/LYSS.png"
                    sx={{ width: "50px", height: "50px" }}
                  />
                </IconButton>
              </Tooltip>
            </Box>

            {/* for larger screen sizes */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "none", lg: "flex" },
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  sx={{ my: 2, color: "black", display: "block" }}
                >
                  {page === "Group Companies" ||
                  page === "Products" ||
                  page === "Initiatives" ||
                  page === "Partners" ? (
                    <span
                      style={{ position: "relative", top: "-4px" }}
                      onClick={(e) => {
                        // setHide(false);
                        setHeading(page);

                        if (page === "Group Companies") {
                          sethandleDropdownGroupCompanisClick(
                            !handleDropdownGroupCompanisClick
                          );
                          sethandleDropdownPartnersClick(false);
                          sethandleDropdownProductsClick(false);
                          sethandleDropdownInitiativesClick(false);
                          setContent([
                            { name: "ATPLC", link: "https://atplc.in" },
                          ]);
                        } else if (page === "Products") {
                          sethandleDropdownProductsClick(
                            !handleDropdownProductsClick
                          );
                          sethandleDropdownInitiativesClick(false);
                          sethandleDropdownGroupCompanisClick(false);
                          sethandleDropdownPartnersClick(false);
                          setContent([
                            {
                              name: "Smartone",
                              link: "https://smartone.lyss.in",
                            },
                          ]);
                        } else if (page === "Initiatives") {
                          sethandleDropdownInitiativesClick(
                            !handleDropdownInitiativesClick
                          );
                          sethandleDropdownGroupCompanisClick(false);
                          sethandleDropdownProductsClick(false);
                          sethandleDropdownPartnersClick(false);
                          setContent([
                            { name: "Student Community", link: "/community" },
                            {
                              name: "Monthly Coding Challenge",
                              link: "examlinks",
                            },
                          ]);
                        } else if (page === "Partners") {
                          sethandleDropdownInitiativesClick(false);
                          sethandleDropdownGroupCompanisClick(false);
                          sethandleDropdownProductsClick(false);
                          sethandleDropdownPartnersClick(
                            !handleDropdownPartnersClick
                          );
                          setContent([
                            {
                              name: "Our Industry Partners",
                              link: "/partners",
                            },
                            {
                              name: "Our Institute Partners",
                              link: "/partners",
                            },
                          ]);
                        }
                      }}
                    >
                      {page}
                      <ArrowDropDown
                        style={{ position: "relative", top: "6px" }}
                        onClick={(e) => {
                          // setHide(false);
                          setHeading(page);

                          if (page === "Group Companies") {
                            sethandleDropdownGroupCompanisClick(
                              !handleDropdownGroupCompanisClick
                            );
                            sethandleDropdownProductsClick(false);
                            sethandleDropdownInitiativesClick(false);
                            setContent([
                              { name: "ATPLC", link: "https://www.atplc.in" },
                            ]);
                          } else if (page === "Products") {
                            sethandleDropdownProductsClick(
                              !handleDropdownProductsClick
                            );
                            sethandleDropdownInitiativesClick(false);
                            sethandleDropdownGroupCompanisClick(false);

                            setContent([
                              {
                                name: "Smartone",
                                link: "https://smartone.lyss.in",
                              },
                            ]);
                          } else if (page === "Initiatives") {
                            sethandleDropdownInitiativesClick(
                              !handleDropdownInitiativesClick
                            );
                            sethandleDropdownGroupCompanisClick(false);
                            sethandleDropdownProductsClick(false);
                            setContent([
                              { name: "Student Community", link: "/community" },
                              {
                                name: "Monthly Coding Challenge",
                                link: "examlinks",
                              },
                            ]);
                          } else if (page === "Partners") {
                            sethandleDropdownInitiativesClick(false);
                            sethandleDropdownGroupCompanisClick(false);
                            sethandleDropdownProductsClick(false);
                            sethandleDropdownPartnersClick(
                              !handleDropdownPartnersClick
                            );
                            setContent([
                              {
                                name: "Our Industry Partners",
                                link: "/partners",
                              },
                              {
                                name: "Our Institute Partners",
                                link: "/partners",
                              },
                            ]);
                          }
                        }}
                      />
                    </span>
                  ) : (
                    <span onClick={() => handleNavigate(page)}>{page}</span>
                  )}
                </Button>
              ))}
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "none", lg: "block", md: "none" },
              }}
            >
              <Tooltip>
                <Button
                  id="button"
                  title="Login"
                  sx={{
                    marginRight: "20px",
                    my: 2,
                    backgroundColor: "#5656b9",
                    borderRadius: "27px",
                    width: "83px",
                    color: "white",
                  }}
                  onClick={(e) => handleNavigate("login")}
                >
                  Login
                </Button>
                <IconButton
                  sx={{ p: 0 }}
                  title="Lyss Technology"
                  onClick={(e) => handleNavigate("")}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src="../images/LYSS.png"
                    sx={{ width: "50px", height: "50px" }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            {handleDropdownInitiativesClick && (
              <Box
                sx={{
                  position: "absolute",
                  top: "65px",
                  left: "400px",
                  backgroundColor: "#fff",
                  boxShadow: "5px -4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 1000,
                  color: "black",
                  padding: "4px",
                  borderRadius: "4px",
                  width: "fit-content",
                }}
              >
                {content.map((data, index) => (
                  <MenuItem
                    onClick={(e) => (window.location.href = `${data.link}`)}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Box>
            )}
            {handleDropdownProductsClick && (
              <Box
                sx={{
                  position: "absolute",
                  top: "65px",
                  left: "150px",
                  backgroundColor: "#fff",
                  boxShadow: "5px -4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 1000,
                  color: "black",
                  padding: "4px",
                  borderRadius: "4px",
                  width: "fit-content",
                }}
              >
                {content.map((data, index) => (
                  <MenuItem
                    key={data.link}
                    onClick={(e) => (window.location.href = `${data.link}`)}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Box>
            )}
            {handleDropdownGroupCompanisClick && (
              <Box
                sx={{
                  position: "absolute",
                  top: "65px",
                  left: "350px",
                  backgroundColor: "#fff",
                  boxShadow: "5px -4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 1000,
                  color: "black",
                  padding: "4px",
                  borderRadius: "4px",
                  width: "fit-content",
                }}
              >
                {content.map((data, index) => (
                  <MenuItem
                    onClick={(e) => (window.location.href = `${data.link}`)}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Box>
            )}
            {handleDropdownPartnersClick && (
              <Box
                sx={{
                  position: "absolute",
                  top: "65px",
                  left: "560px",
                  backgroundColor: "#fff",
                  boxShadow: "5px -4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 1000,
                  color: "black",
                  padding: "4px",
                  borderRadius: "4px",
                  width: "fit-content",
                }}
              >
                {content.map((data, index) => (
                  <MenuItem
                    onClick={(e) => {
                      if (heading === "Partners") {
                        navigate(`${data.link}/${data.name}`);
                      } else window.location.href = `${data.link}`;
                    }}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Box>
            )}
          </Toolbar>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "flex", lg: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon style={{ color: "black" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "block", lg: "none" } }}
              >
                <MenuItem>
                  <ArrowLeft
                    onClick={(e) => setHide(true)}
                    style={{ position: "relative", right: "5px" }}
                  />
                  {heading}
                </MenuItem>

                {content.map((data, index) => (
                  <MenuItem
                    onClick={(e) => {
                      if (heading === "Partners") {
                        navigate(`/${data.link}/${data.name}`);
                      } else window.location.href = `${data.link}`;
                    }}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: "block", sm: "block", lg: "none", md: "block" },
              }}
            >
              <Tooltip title="Lyss Technology">
                <Button
                  id="button"
                  sx={{
                    marginRight: "20px",
                    my: 2,
                    backgroundColor: "#5656b9",
                    borderRadius: "27px",
                    width: "83px",
                    color: "white",
                  }}
                >
                  Login
                </Button>
                <IconButton sx={{ p: 0 }}>
                  <Avatar
                    alt="Remy Sharp"
                    src="../images/LYSS.png"
                    sx={{ width: "50px", height: "50px" }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      )}
    </AppBar>
  );
};
